import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetcher } from '../../utils/requests';
import Loading from '../loading/Loading';
import './Login.scss';

const LoginButton = ({ form }) => {
	const [isValidForm, setIsValidForm] = useState(false);

	// Watch all values
	const formValues = Form.useWatch([], form);

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				setIsValidForm(true);
			},
			() => {
				setIsValidForm(false);
			},
		);
	}, [form, formValues]);

	return (
		<Button type='primary' htmlType='submit' disabled={!isValidForm}>
			Inciar Sesión
		</Button>
	);
};

const Login = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ field: '', message: '' });
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const onFinish = ({ username, password }) => {
		setIsLoading(true);
		fetcher(
			'login',
			{
				method: 'POST',
				body: {
					username,
					password,
				},
			},
			() => {
				setIsLoading(false);
				navigate('/');
			},
			({ data, status }) => {
				setIsLoading(false);
				if (status === 404) {
					setError(data.message);
				} else {
					setError({
						field: 'general',
						message: 'Ocurrió un error, intentalo más tarde',
					});
				}
			},
		);
	};

	return (
		<div className='login-container'>
			{isLoading && <Loading />}
			<Form
				form={form}
				name='normal_login'
				className='login-form'
				initialValues={{ remember: true }}
				onFinish={onFinish}
			>
				<Form.Item
					name='username'
					rules={[{ required: true, message: 'Por favor ingesa el nombre de usuario' }]}
				>
					<Input
						prefix={<UserOutlined className='site-form-item-icon' />}
						placeholder='Usuario'
						status={error.field === 'username' ? 'error' : null}
					/>
				</Form.Item>
				<Form.Item
					name='password'
					rules={[{ required: true, message: 'Porfavor ingresa la contraseña' }]}
				>
					<Input.Password
						prefix={<LockOutlined className='site-form-item-icon' />}
						placeholder='Contraseña'
						status={error.field === 'password' ? 'error' : null}
					/>
				</Form.Item>
				<Form.Item>
					<LoginButton form={form} />
				</Form.Item>
			</Form>
		</div>
	);
};

export default Login;
