import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import {
	PlusOutlined,
	/*SwapOutlined,*/
	DollarOutlined,
	HistoryOutlined,
	SettingOutlined,
	ContactsOutlined,
	WifiOutlined,
} from '@ant-design/icons';
import InterglobalLogo from '../../assets/interglobal-logo.png';
import { useNavigate } from 'react-router-dom';
import './SideBar.scss';

const getItem = (label, key, icon, children, type) => ({
	key,
	icon,
	children,
	label,
	type,
});

const items = [
	getItem('Nueva instalación', 'newInstallation', <PlusOutlined />),
	// getItem('Traslado', 'clientTransfer', <SwapOutlined />),
	getItem('Pagos', 'payments', <DollarOutlined />),
	getItem('Histórico', 'history', <HistoryOutlined />),
	getItem('Configuración', 'configuration', <SettingOutlined />, [
		getItem('Proveedores', 'providers', <WifiOutlined />),
		getItem('Planes', 'plans', <ContactsOutlined />),
	]),
];

const SideBar = () => {
	const [collapsed, setCollapsed] = useState(true);
	const [touchStart, setTouchStart] = useState(0);

	const navigate = useNavigate();

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const onClick = e => !e.children && navigate(e.key);

	const handleTouchStart = event => {
		const {
			touches: [{ clientX }],
		} = event;
		setTouchStart(clientX);
	};

	const handleTouchEnd = event => {
		const {
			changedTouches: [{ clientX: touchEnd }],
		} = event;

		if (touchStart > touchEnd) {
			setCollapsed(true);
		} else if (touchStart < touchEnd) {
			setCollapsed(false);
		}
		setTouchStart(0);
	};

	const { Sider } = Layout;

	return (
		<Sider
			id='side-bar'
			collapsible
			collapsed={collapsed}
			onCollapse={toggleCollapsed}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			collapsedWidth={40}
		>
			<div className='logo-container'>
				{!collapsed && (
					<img className='sidebar-logo' src={InterglobalLogo} alt='interglobal-logo' />
				)}
			</div>

			<Menu onClick={onClick} mode='vertical' items={items} />
		</Sider>
	);
};

export default SideBar;
