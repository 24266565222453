import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../utils/requests';
import { Button, Layout, Tooltip } from 'antd';
import Loading from '../../../components/loading/Loading';
import { Content, Header } from 'antd/es/layout/layout';
import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import PlansModal, { formatPlanSpeed } from './modal/PlansModal';
import { formatCurrency } from '../../../utils/formatters';
import TableComponent from '../../../components/table/TableComponent';
import './Plans.scss';


const HEADER_FORMATTERS = {
	provider: () => 'Proveedor',
	planSpeedValue: () => 'Megas',
	planCost: () => 'Precio',
};

const DATA_FORMATTERS = {
	planCost: v => formatCurrency(v),
	provider: (v, provider) => provider?.find(p => p.id === v)?.providerType,
	planSpeedValue: v => formatPlanSpeed(v),
};

const getProviders = (setProviders, setError, setIsLoading) => {
	fetcher(
		'providers',
		{ method: 'GET' },
		response => {
			setProviders(response);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const getPlans = (setPlans, setError, setIsLoading) => {
	fetcher(
		'plans',
		{ method: 'GET' },
		response => {
			setPlans(response);
			setIsLoading(false);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const getTableData = (setSchema, setPlans, setProviders, setIsLoading, setError) => {
	fetcher(
		'plans/schema',
		{ method: 'GET' },
		response => {
			setSchema(response);
			getProviders(
				r => {
					setProviders(r);
					getPlans(setPlans, setError, setIsLoading);
				},
				setError,
				setIsLoading,
			);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const Plans = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [columns, setColumns] = useState([]);
	const [plans, setPlans] = useState([]);
	const [providers, setProviders] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});

	useEffect(() => {
		setIsLoading(true);
		getTableData(setColumns, setPlans, setProviders, setIsLoading, error => console.log(error));
	}, []);

	const onRowClicked = plan => {
		setOpenModal(true);
		setSelectedRow(plan);
	};

	const handleModalCancel = () => {
		setOpenModal(false);
		setSelectedRow({});
	};

	const handleModalOk = values => {
		setOpenModal(false);
		setIsLoading(true);
		if (values.id) {
			fetcher(
				'plans',
				{ method: 'PUT', body: values },
				() => {
					setPlans(plans.map(p => (p.id === values.id ? { ...values } : p)));
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		} else {
			fetcher(
				'plans',
				{ method: 'POST', body: values },
				({ id }) => {
					setPlans([...plans, { ...values, id }]);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		}
		setSelectedRow({});
	};

	const handleDelete = id => {
		setOpenModal(false);
		setIsLoading(true);
		fetcher(
			'plans',
			{ method: 'DELETE', body: { id } },
			response => {
				setPlans(plans.filter(p => p.id !== id));
				setIsLoading(false);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
		setSelectedRow({});
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='plans'>
				<Header className='plans-header'>
					<span className='title'>
						Planes
						<Tooltip title='Planes configurados en los diferentes Router Boards'>
							<QuestionCircleOutlined style={{ color: 'blue', fontSize: 14 }} />
						</Tooltip>
					</span>
				</Header>
				<Content className='plans-content'>
					<Button
						className='create-button'
						type='primary'
						icon={<PlusCircleOutlined />}
						onClick={() => {
							setOpenModal(true);
							setSelectedRow({});
						}}
					>
						Nuevo plan
					</Button>
					<TableComponent
						dependencies={{ provider: providers }}
						headerFormatters={HEADER_FORMATTERS}
						dataFormatters={DATA_FORMATTERS}
						data={plans}
						columns={columns}
						onClick={onRowClicked}
					/>
					<PlansModal
						open={openModal}
						onCancel={handleModalCancel}
						onOk={handleModalOk}
						onDelete={handleDelete}
						values={selectedRow}
						dependencies={{ provider: providers }}
					/>
				</Content>
			</Layout>
		</>
	);
};

export default Plans;
