import { Avatar, Dropdown } from 'antd';
import React, { useState } from 'react';
import { getUserData } from '../../utils/auth';
import { UserOutlined } from '@ant-design/icons';
import Loading from '../loading/Loading';
import { useNavigate } from 'react-router-dom';
import './User.scss';
import { logout } from '../../utils/requests';

const items = [
	{
		key: 'username',
		type: 'group',
	},
	{
		type: 'divider',
	},
	{
		key: 'profile',
		label: 'Perfil',
	},
	{
		key: 'logout',
		label: 'Cerrar Sesión',
	},
];

const getItems = username => items.map(i => (i.key === 'username' ? { ...i, label: username } : i));

const User = ({ className }) => {
	const [isLoading, setIsLoding] = useState(false);
	const { username } = getUserData();
	const navigate = useNavigate();
	const logoutUser = () => {
		setIsLoding(true);
		logout(
			response => {
				setIsLoding(false);
				navigate('/login');
			},
			error => {
				console.log(error);
				setIsLoding(false);
			},
			navigate
		);
	};

	const handleClick = async ({ key }) => {
		if (key === 'logout') {
			logoutUser();
		}
		if (key === 'profile') {
			navigate('/profile');
		}
	};
	return (
		<div className={`user-menu ${className || ''}`}>
			{isLoading && <Loading />}
			<Dropdown
				menu={{ items: getItems(username), onClick: handleClick }}
				placement='topRight'
				arrow
			>
				<Avatar
					size={40}
					icon={<UserOutlined />}
					style={{ backgroundColor: '#e6f4ff', color: 'black' }}
				/>
			</Dropdown>
		</div>
	);
};

export default User;
