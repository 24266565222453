import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select } from 'antd';
import { fetcher } from '../../utils/requests';
import { DollarOutlined } from '@ant-design/icons';
import { currencyParser, formatCurrency } from '../../utils/formatters';

const getProviders = (onSuccess, onError) =>
	fetcher(
		'providers',
		{
			method: 'GET',
		},
		onSuccess,
		onError,
	);

const getPlans = (params, onSuccess, onError) =>
	fetcher(
		'plans',
		{
			method: 'GET',
			params,
		},
		onSuccess,
		onError,
	);

const PlanForm = ({ onFinish, submitText, onCancel, cancelText, values }) => {
	const [providers, setProviders] = useState([]);
	const [isProviderLoading, setIsProviderLoading] = useState(false);

	const [plans, setPlans] = useState([]);
	const [isPlanLoading, setIsPlanLoading] = useState(false);

	const [form] = Form.useForm();
	const formValues = Form.useWatch([], form);
	const provider = formValues?.provider;

	useEffect(() => {
		if (Object.keys(values).length) {
			form.setFieldsValue(values);
			form.setFieldValue('planSpeed', values.planSpeed);
		}
		setIsProviderLoading(true);
		getProviders(
			providers => {
				setIsProviderLoading(false);
				setProviders(providers);
			},
			({ data, status }) => {
				console.log(data, status);
			},
		);

		getPlans(
			provider ? { provider } : {},
			p => {
				setIsPlanLoading(false);
				setPlans(p);
			},
			({ data, status }) => {
				setIsPlanLoading(false);
				console.log(status, data);
			},
		);
	}, [values, form, provider]);

	return (
		<Form
			form={form}
			name='plan_form'
			className='plan-form'
			onFinish={value => onFinish(form, value)}
			layout='vertical'
		>
			<Form.Item
				name='provider'
				rules={[{ required: true, message: 'El tipo de conexión es obligatorio' }]}
				label='Conexión'
			>
				<Select
					loading={isProviderLoading}
					options={providers.map(provider => ({
						value: provider.id,
						label: provider.providerType,
					}))}
				/>
			</Form.Item>

			<Form.Item
				name='planSpeed'
				rules={[{ required: true, message: 'El plan es obligatorio' }]}
				label='Plan'
			>
				<Select
					loading={isPlanLoading}
					onChange={value => {
						form.setFieldValue('planCost', plans.find(p => p.id === value).planCost);
					}}
					options={plans
						.filter(plan => plan.provider === provider)
						.map(plan => ({
							value: plan.id,
							label: plan.planSpeed,
						}))}
					disabled={!provider}
				/>
			</Form.Item>

			<Form.Item
				name='planCost'
				rules={[{ required: true, message: 'El tipo de conexión es obligatorio' }]}
				label='Pago'
			>
				<InputNumber
					style={{ width: '100%' }}
					formatter={formatCurrency}
					parser={currencyParser}
					prefix={<DollarOutlined />}
					disabled={!form.getFieldValue('planSpeed')}
				/>
			</Form.Item>

			<Form.Item
				name='paymentDate'
				rules={[{ required: true, message: 'El dia de pago es obligatorio' }]}
				label='Dia de Pago'
			>
				<Select
					loading={isPlanLoading}
					options={['15', '30'].map(v => ({
						value: v,
						label: v,
					}))}
				/>
			</Form.Item>

			<Form.Item className='form-controls'>
				<Button type='primary' htmlType='submit'>
					{submitText}
				</Button>
				{onCancel && (
					<Button type='text' onClick={onCancel}>
						{cancelText}
					</Button>
				)}
			</Form.Item>
		</Form>
	);
};

export default PlanForm;
