import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './components/login/Login';
import SideBar from './components/sidebar/SideBar';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import NewInstallation from './pages/newInstallation/NewInstallation';
import Header from './components/header/Header';
import Content from './components/content/Content';
import Footer from './components/footer/Footer';
import ClientTransfer from './pages/clientTransfer/ClientTransfer';
import Payments from './pages/payments/Payments';
import History from './pages/history/History';
import Profile from './pages/profile/Profile';
import { Layout } from 'antd';
import './App.scss';
import Plans from './pages/configuration/plans/Plans';
import Providers from './pages/configuration/providers/Providers';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<Layout className='home-layout'>
					<SideBar />
					<Layout className='navigation-layout'>
						<Header />
						<Content />
						<Footer />
					</Layout>
				</Layout>
			</ProtectedRoute>
		),
		children: [
			{
				path: '/newInstallation',
				element: <NewInstallation />,
			},
			{
				path: '/clientTransfer',
				element: <ClientTransfer />,
			},
			{
				path: '/payments',
				element: <Payments />,
			},
			{
				path: '/history',
				element: <History />,
			},
			{
				path: '/profile',
				element: <Profile />,
			},
			{
				path: '/plans',
				element: <Plans />,
			},
			{
				path: '/providers',
				element: <Providers />,
			},
		],
	},
	{
		path: '/login',
		element: <Login />,
	},
]);

function App() {
	return (
		<div className='app'>
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
