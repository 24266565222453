import React from 'react'
import { Outlet } from 'react-router-dom';
import {Layout} from 'antd';
import "./Content.scss";

const {Content: AntDContent} = Layout;

const Content = () => {
  return (
		<AntDContent className="app-content">
			<Outlet />
		</AntDContent>
  );
}

export default Content