import { Table } from 'antd';
import React from 'react';

const TableComponent = ({
	data,
	columns,
	headerFormatters,
	dataFormatters,
	onClick,
	dependencies,
}) => {
    console.log({data, dataFormatters});
	const tableColumns =
		data && data.length
			? columns?.map(column => ({
					title: headerFormatters[column] ? headerFormatters[column] : column,
					dataIndex: column,
					key: column,
			  }))
			: [];
	const dataSource =
		data && data.length
			? data.map(d =>
					Object.keys(d).reduce(
						(acc, key) => ({
							...acc,
							[key]:
								dataFormatters && dataFormatters[key]
									? dependencies && dependencies[key]
										? dataFormatters[key](d[key], dependencies[key])
										: dataFormatters[key](d[key])
									: d[key],
						}),
						{},
					),
			  )
			: [];
	return (
		<Table
			scroll={{ x: true }}
			columns={tableColumns}
			dataSource={dataSource}
			pagination={{ position: ['bottomCenter'], pageSize: 4 }}
			onRow={record => ({
				onClick: () => onClick(dataSource.find(p => p.id === record.id)),
			})}
		/>
	);
};

export default TableComponent;
