import React, { useState } from 'react';
import { Button, Layout, Result, Steps, message } from 'antd';
import PlanForm from '../../components/forms/PlanForm';
import ClientForm from '../../components/forms/ClientForm';
import RouterForm from './forms/RouterForm';
import { fetcher } from '../../utils/requests';
import Loading from '../../components/loading/Loading';
import './NewInstallation.scss';

const { Header, Content } = Layout;

const items = [
	{
		key: 'client-form',
		title: 'Cliente',
	},
	{
		key: 'plan-form',
		title: 'Plan',
	},
	{
		key: 'network-form',
		title: 'Red',
	},
];

const NewInstallation = () => {
	const [current, setCurrent] = useState(0);
	const [clientValues, setClientValues] = useState({});
	const [planValues, setPlanValues] = useState({});
	const [routerValues, setRouterValues] = useState({});
	const [messageApi, contextHolder] = message.useMessage();
	const [showResult, setShowResult] = useState(false);
	const [createdIp, setCreatedIp] = useState('');
	const [isLoading, setIsLoading] = useState('');

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const resetState = () => {
		setCurrent(0);
		setClientValues({});
		setPlanValues({});
		setRouterValues({});
	};

	const onFinish = async (form, value) => {
		switch (form.__INTERNAL__.name) {
			case 'client_form':
				next();
				setClientValues(value);
				break;

			case 'plan_form':
				next();
				setPlanValues(value);
				break;

			case 'router_form':
				setRouterValues(value);
				setIsLoading(true);
				await fetcher(
					'newInstallations',
					{
						method: 'POST',
						body: { plan: planValues, client: clientValues, router: value },
					},
					({ ip }) => {
						setIsLoading(false);
						setCreatedIp(ip);
						setShowResult(true);
						messageApi.success('Se creó una instalación!', 5);
						next();
						resetState();
					},
					() => {
						messageApi.error('Ocurrió un error, intentalo de nuevo', 5);
						setIsLoading(false);
					},
				);
				break;

			default:
				break;
		}
	};

	const content = [
		<ClientForm values={clientValues} onFinish={onFinish} submitText='Siguiente' />,
		<PlanForm
			values={planValues}
			onFinish={onFinish}
			submitText='Siguiente'
			onCancel={prev}
			cancelText='Anterior'
		/>,
		<RouterForm
			values={routerValues}
			onFinish={onFinish}
			submitText='Finalizar'
			onCancel={prev}
			cancelText='Anterior'
		/>,
	];

	return (
		<Layout className='new-installation'>
			{isLoading && <Loading />}
			{contextHolder}
			<Header className='new-installation-header'>
				<span className="title">Nueva Instalación</span>
				<Steps size='small' items={items} current={current} direction='horizontal' />
			</Header>
			<Content className='new-installation-content'>{content[current]}</Content>
			{showResult && (
				<div className='new-installation-info-container'>
					<Result
						title={`IP del nuevo plan: ${createdIp}`}
						extra={
							<Button
								type='primary'
								onClick={() => {
									setShowResult(false);
									setCreatedIp('');
								}}
							>
								Cerrar
							</Button>
						}
					/>
				</div>
			)}
		</Layout>
	);
};

export default NewInstallation;
