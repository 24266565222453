import React from 'react';
import { Layout } from 'antd';
import "./Footer.scss";

const { Footer: AntDFooter } = Layout;

const Footer = () => {
	return (
		<AntDFooter className="app-footer">
			Instalaciones Interglobal ©{new Date().getFullYear()} Creado por Interglobal IMC
		</AntDFooter>
	);
};

export default Footer;
