import React, { useEffect } from 'react';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';

const DEFAULT_VALUES = {
	providerType: null,
	publicIp: null,
	username: null,
	password: null,
	routerboard: null,
};

export const hidePassword = password =>
	password
		.split('')
		.map(c => '*')
		.join('')
		.toString();

const ModalOptions = ({ id, onOk, onDelete }) => (
	<>
		<Button type='primary' onClick={onOk}>
			Guardar
		</Button>
		<Button type='primary' danger onClick={onDelete} icon={<DeleteOutlined />}>
			Eliminar
		</Button>
	</>
);

const ProvidersModal = ({ values, open, onOk, onCancel, onDelete }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		console.log(values);
		if (values.id) {
			form.setFieldsValue(values);
		} else {
			form.setFieldsValue(DEFAULT_VALUES);
		}
	}, [form, values]);

	const handleCancel = () => {
		form.resetFields({});
		onCancel();
	};

	const handleDelete = () => {
		form.resetFields();
		form.setFieldsValue({});
		onDelete(values.id);
	};

	return (
		<>
			<Modal
				title={values.id ? 'Modificar plan' : 'Crear plan'}
				open={open}
				onCancel={onCancel}
				footer={
					<ModalOptions
						onOk={() => {
							const formValues = form.getFieldsValue();
							onOk({
								...formValues,
								id: values.id,
							});
						}}
						onCancel={handleCancel}
						onDelete={() =>
							Modal.confirm({
								title: 'Estas seguro que deseas eliminar este proveedor?',
								okText: 'Eliminar',
								cancelText: 'Cancelar',
								onOk: handleDelete,
							})
						}
					/>
				}
			>
				<Form className='password-form' form={form} name='password_form' layout='vertical'>
					<Form.Item
						name='providerType'
						label={values.providerType ? 'Nombre proveedor' : ''}
						rules={[{ required: true, message: 'Proveedor es olbigatorio' }]}
					>
						<Input
							placeholder='Nombre proveedor'
							prefix={<UserOutlined className='site-form-item-icon' />}
						/>
					</Form.Item>

					<Form.Item
						name='publicIp'
						label={values.publicIp ? 'Ip de winbox' : ''}
						rules={[{ required: true, message: 'Velocidad del plan es obligatorio' }]}
					>
						<Input
							placeholder='Ip de winbox'
							prefix={<UserOutlined className='site-form-item-icon' />}
						/>
					</Form.Item>

					<Form.Item
						name='username'
						label={values.username ? 'Usuario' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input
							placeholder='Usuario'
							prefix={<UserOutlined className='site-form-item-icon' />}
						/>
					</Form.Item>

					<Form.Item
						name='password'
						label={values.password ? 'Contraseña' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input.Password
							placeholder='Contraseña'
							prefix={<UserOutlined className='site-form-item-icon' />}
							visibilityToggle={false}
						/>
					</Form.Item>

					<Form.Item
						name='routerboard'
						label={values.routerboard ? 'Referencia routerboard' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input
							placeholder='Referencia routerboard'
							prefix={<UserOutlined className='site-form-item-icon' />}
							visibilityToggle={false}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default ProvidersModal;
