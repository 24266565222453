import { getUserData } from './auth';
export const fetcher = (url, { method, body, params }, onSuccess, onError = error => console.log(error)) => {
    const { id } = getUserData();
    fetch(`api/${url}${params ? `?${new URLSearchParams(params)}` : ''}`, {
        method,
        body: body ? JSON.stringify({ ...body, date: new Date(), user: id }) : null,
    })
        .then(async response => {
            response.ok
                ? onSuccess(await response.json())
                : onError({
                    status: response.status,
                    data: await response.json(),
                });
        })
        .catch(error => onError(error));
};

export const logout = (onSuccess, onError, navigate) => fetcher(
    'logout',
    { method: 'GET' },
    response => {
        onSuccess(response);
        navigate('/login', { replace: true });
    },
    error => onError(error)
);