import React from 'react';
import { Button, Form, Input } from 'antd';
import { BoxPlotOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';

const RouterForm = ({ onFinish, submitText, onCancel, cancelText, values }) => {
	const [form] = Form.useForm();
	if (values) form.setFieldsValue(values);
	return (
		<Form
			form={form}
			name='router_form'
			className='router-form'
			defaultValue={{ wifiName: null, wifiPassword: null }}
			onFinish={value => onFinish(form, value)}
			layout='vertical'
		>
			<Form.Item name='wifiName' rules={[{ required: true, message: 'Wifi es obligatorio' }]}>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder='Nombre del Wifi'
				/>
			</Form.Item>

			<Form.Item
				name='wifiPassword'
				rules={[{ required: true, message: 'Contraseña del Wifi es obligatorio' }]}
			>
				<Input.Password
					prefix={<LockOutlined className='site-form-item-icon' />}
					placeholder='Contraseña del Wifi'
					autoComplete='new-password'
				/>
			</Form.Item>

			<Form.Item
				name='napBoxConnector'
				rules={[{ required: true, message: 'Conector de Caja NAP es obligatorio' }]}
			>
				<Input
					prefix={<BoxPlotOutlined className='site-form-item-icon' />}
					placeholder='Conector Caja NAP'
				/>
			</Form.Item>

			<Form.Item name='comments'>
				<Input.TextArea placeholder='Comentarios' />
			</Form.Item>

			<Form.Item className='form-controls'>
				<Button type='primary' htmlType='submit'>
					{submitText}
				</Button>
				{onCancel && (
					<Button type='text' onClick={onCancel}>
						{cancelText}
					</Button>
				)}
			</Form.Item>
		</Form>
	);
};

export default RouterForm;
