import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { UserOutlined, IdcardOutlined, PhoneOutlined } from '@ant-design/icons';

const ClientForm = ({ onFinish, submitText, onCancel, cancelText, values }) => {
	const [form] = Form.useForm();
	if(values) form.setFieldsValue(values);
	return (
		<Form
			form={form}
			name='client_form'
			className='client-form'
			onFinish={value => onFinish(form, value)}
			layout='vertical'
		>
			<Form.Item
				name='firstName'
				rules={[{ required: true, message: 'Nombres es obligatorio' }]}
			>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder='Nombres'
				/>
			</Form.Item>

			<Form.Item
				name='lastName'
				rules={[{ required: true, message: 'Apellidos es obligatorio' }]}
			>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder='Apellidos'
				/>
			</Form.Item>

			<Form.Item
				name='personalId'
				rules={[{ required: true, message: 'Cédula es obligatorio' }]}
			>
				<InputNumber
					style={{ width: '100%' }}
					prefix={<IdcardOutlined className='site-form-item-icon' />}
					placeholder='Cédula'
					maxLength={15}
				/>
			</Form.Item>

			<Form.Item
				name='cellphone'
				rules={[{ required: true, message: 'Celular es obligatorio' }]}
			>
				<InputNumber
					style={{ width: '100%' }}
					prefix={<PhoneOutlined className='site-form-item-icon' />}
					placeholder='Celular'
					maxLength={10}
				/>
			</Form.Item>

			<Form.Item
				name='address'
				rules={[{ required: true, message: 'La dirección es obligatoria' }]}
			>
				<Input.TextArea placeholder='Dirección' />
			</Form.Item>

			<Form.Item className='form-controls'>
				<Button type='primary' htmlType='submit'>
					{submitText}
				</Button>
				{onCancel && (
					<Button type='text' onClick={onCancel}>
						{cancelText}
					</Button>
				)}
			</Form.Item>
		</Form>
	);
};

export default ClientForm;
