import React, { useState } from 'react';
import { Layout, Steps, message } from 'antd';
import { fetcher } from '../../utils/requests';
import ClientForm from '../../components/forms/ClientForm';
import RouterForm from './forms/RouterForm';
import PlanForm from '../../components/forms/PlanForm';
import Loading from '../../components/loading/Loading';
import './History.scss';

const { Header, Content } = Layout;

const items = [
	{
		key: 'client-form',
		title: 'Cliente',
	},
	{
		key: 'plan-form',
		title: 'Plan',
	},
	{
		key: 'router-form',
		title: 'Red',
	},
];

const History = () => {
	const [current, setCurrent] = useState(0);
	const [clientValues, setClientValues] = useState({});
	const [planValues, setPlanValues] = useState({});
	const [routerValues, setRouterValues] = useState({});
	const [messageApi, contextHolder] = message.useMessage();
	const [isLoading, setIsLoading] = useState(false);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const resetState = () => {
		setCurrent(0);
		setClientValues({});
		setPlanValues({});
		setRouterValues({});
	};

	const onFinish = async (form, value) => {
		console.log(form.__INTERNAL__.name, value);
		switch (form.__INTERNAL__.name) {
			case 'client_form':
				next();
				setClientValues(value);
				break;

			case 'plan_form':
				next();
				setPlanValues(value);
				break;

			case 'router_form':
				setRouterValues(value);
				setIsLoading(true);
				await fetcher(
					'history',
					{
						method: 'POST',
						body: { plan: planValues, client: clientValues, router: value },
					},
					() => {
						messageApi.success('Se registró correctamente', 5);
						setIsLoading(false);
						next();
						resetState();
					},
					() => {
						messageApi.error('Ocurrió un error, intentalo de nuevo', 5);
						setIsLoading(false);
					},
				);
				break;

			default:
				break;
		}
	};

	const content = [
		<ClientForm values={clientValues} onFinish={onFinish} submitText='Siguiente' />,
		<PlanForm
			values={planValues}
			onFinish={onFinish}
			submitText='Siguiente'
			onCancel={prev}
			cancelText='Anterior'
		/>,
		<RouterForm
			values={routerValues}
			onFinish={onFinish}
			submitText='Finalizar'
			onCancel={prev}
			cancelText='Anterior'
		/>,
	];

	return (
		<Layout className='history'>
			{isLoading && <Loading />}
			{contextHolder}
			<Header className='history-header'>
                <span className='title'>Histórico</span>
				<Steps size='small' items={items} current={current} direction='horizontal' />
			</Header>
			<Content className='history-content'>
				{content[current]}
			</Content>
		</Layout>
	);
};

export default History;
